@import "../../../../assets/scss/styles";

.text-promotion {
  width: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  text-align: center;
  padding: 15px 0px;
  h1 {
    margin: 0;
    @extend %montserrat-300-bold;
    font-size: to-rem(130);
    @media #{$breakpoint-small} {
      font-size: to-rem(30);
    }
  }
  h4 {
    margin: 0;
    @extend %montserrat-600-bold;
    @media #{$breakpoint-small} {
      font-size: to-rem(20);
    }
    span {
      @extend %montserrat-400;
      font-style: oblique;
      @media #{$breakpoint-small} {
        font-size: to-rem(18);
      }
    }
  }
}

.text-restaurant {
  width: 100%;
  text-align: center;
  p {
    @extend %montserrat-400;
    font-style: italic;
    line-height: 220%;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    @media #{$breakpoint-small} {
      line-height: 1.5;
      background-color: rgba(0, 0, 0, 0.4);
      font-size: to-rem(20);
    }
    span {
      @extend %montserrat-700-bold;
      font-style: normal;
      line-height: 20%;
      margin: 0;
      @media #{$breakpoint-small} {
        font-size: to-rem(25);
        background-color: black;
        line-height: 1.5;
      }
    }
  }
}

.text-arepa {
  width: 100%;
  font-size: to-rem(25) !important;
  font-style: italic;
  line-height: 150%;
  @media #{$breakpoint-small} {
    width: 100%;
    font-size: to-rem(20) !important;
  }
  h1 {
    @extend %montserrat-500-bold;
    font-style: normal;
    margin: 0;
    padding: 0;
    @media #{$breakpoint-small} {
      font-size: to-rem(40) !important;
      line-height: 95%;
    }
  }
  span {
    @media #{$breakpoint-small} {
      display: none;
    }
  }
}

.text-domicile {
  width: 100%;
  text-align: center;
  h3 {
    @extend %montserrat-600-bold;
    font-size: to-rem(70);
    line-height: 90%;
    @media #{$breakpoint-small} {
      font-size: to-rem(22.5);
    }
    span {
      @extend %montserrat-100;
      line-height: 90%;
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: to-rem(25);
      margin-left: to-rem(100);
      @media #{$breakpoint-small} {
        font-size: to-rem(13) !important;
        margin-left: to-rem(20);
      }
    }
  }
}

.text-hamburger {
  width: 100%;
  h3 {
    width: 100%;
    @extend %montserrat-600-bold;
    font-size: to-rem(50);
    line-height: 90%;
    @media #{$breakpoint-small} {
      font-size: to-rem(20);
    }
  }
  p {
    @extend %montserrat-200;
    line-height: 130%;
    width: 100%;
    font-style: italic;
    @media #{$breakpoint-small} {
      display: none;
    }
  }
}

.text-new-line-products {
  width: 100%;
  line-height: 100%;
  p {
    @extend %montserrat-500-bold;
    font-size: to-rem(30);
    text-decoration: underline;
    line-height: 140%;
    @media #{$breakpoint-small} {
      font-size: to-rem(15);
      line-height: 60%;
    }
  }
  h4 {
    @extend %montserrat-500-bold;
    font-size: to-rem(80);
    font-style: normal;
    line-height: 50%;
    text-decoration: normal !important;
    @media #{$breakpoint-small} {
      font-size: to-rem(50);
      text-decoration: normal !important;
      line-height: 80%;
    }
    span {
      @extend %montserrat-100;
      text-decoration: none;
      font-size: to-rem(30);
      font-style: italic;
      @media #{$breakpoint-small} {
        font-size: to-rem(14);
        background-color: black;
        text-decoration: normal !important;
      }
    }
  }
}
