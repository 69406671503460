@font-face {
  font-family: "montserrat-light";
  src: local("Montserrat-Light"),
    url(../fonts/Montserrat-Light.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-medium";
  src: local("Montserrat-Medium"),
    url(../fonts/Montserrat-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "montserrat-bold";
  src: local("Montserrat-Bold"),
    url(../fonts/Montserrat-Bold.ttf) format("truetype");
}
