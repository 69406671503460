//use: @extend %montserrat-100;
%montserrat-100 {
  font-family: $font-family-light;
  font-size: to-rem(10);
}

%montserrat-100-medium {
  font-family: $font-family-medium;
  font-size: to-rem(10);
}

%montserrat-100-bold {
  font-family: $font-family-bold;
  font-size: to-rem(10);
}

%montserrat-200 {
  font-family: $font-family-light;
  font-size: to-rem(20);
}

%montserrat-200-medium {
  font-family: $font-family-medium;
  font-size: to-rem(20);
}

%montserrat-200-bold {
  font-family: $font-family-bold;
  font-size: to-rem(20);
}

%montserrat-300 {
  font-family: $font-family-light;
  font-size: to-rem(30);
}

%montserrat-300-medium {
  font-family: $font-family-medium;
  font-size: to-rem(30);
}

%montserrat-300-bold {
  font-family: $font-family-bold;
  font-size: to-rem(30);
}

%montserrat-400 {
  font-family: $font-family-light;
  font-size: to-rem(40);
}

%montserrat-400-medium {
  font-family: $font-family-medium;
  font-size: to-rem(40);
}

%montserrat-400-bold {
  font-family: $font-family-bold;
  font-size: to-rem(40);
}

%montserrat-500 {
  font-family: $font-family-light;
  font-size: to-rem(50);
}

%montserrat-500-medium {
  font-family: $font-family-medium;
  font-size: to-rem(50);
}

%montserrat-500-bold {
  font-family: $font-family-bold;
  font-size: to-rem(50);
}

%montserrat-600 {
  font-family: $font-family-light;
  font-size: to-rem(60);
}

%montserrat-600-medium {
  font-family: $font-family-medium;
  font-size: to-rem(60);
}

%montserrat-600-bold {
  font-family: $font-family-bold;
  font-size: to-rem(60);
}

%montserrat-700 {
  font-family: $font-family-light;
  font-size: to-rem(70);
}

%montserrat-700-medium {
  font-family: $font-family-medium;
  font-size: to-rem(70);
}

%montserrat-700-bold {
  font-family: $font-family-bold;
  font-size: to-rem(70);
}

%montserrat-800 {
  font-family: $font-family-light;
  font-size: to-rem(80);
}

%montserrat-800-medium {
  font-family: $font-family-medium;
  font-size: to-rem(80);
}

%montserrat-800-bold {
  font-family: $font-family-bold;
  font-size: to-rem(80);
}

%montserrat-900 {
  font-family: $font-family-light;
  font-size: to-rem(90);
}

%montserrat-900-medium {
  font-family: $font-family-medium;
  font-size: to-rem(90);
}

%montserrat-900-bold {
  font-family: $font-family-bold;
  font-size: to-rem(90);
}
