.button-shopping {
  width: 60px;
  height: 60px;
  background-color: white;
  position: fixed;
  bottom: 35px;
  right: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3.5px solid #e9d2ab;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &:active {
    transform: scale3d(0.9, 0.9, 1);
    -webkit-transform: scale3d(0.9, 0.9, 1);
  }
}
