@import "../../../../assets/scss/styles";

.line-separator {
  width: 100%;
  height: 10px;
  background-color: rgb(245, 164, 0);
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .fondo {
    width: 800px;
    height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    background-image: url("../../../../assets/images/corporateImage/png/logoErepaCafe.png");
    bottom: -250px;
    right: -105px;
    opacity: 0.06;
    @media #{$breakpoint-small} {
      width: 450px;
      height: 450px;
      bottom: -135px;
      right: -60px;
    }
  }
  .container {
    width: 85%;
    height: 800px;
    margin: 80px 0;
    display: flex;
    background-color: rgb(108, 73, 17);
    -webkit-box-shadow: 0px 7px 26px 1px rgba(122, 122, 122, 0.8);
    -moz-box-shadow: 0px 7px 26px 1px rgba(122, 122, 122, 0.8);
    box-shadow: 0px 7px 26px 1px rgba(122, 122, 122, 0.8);
    @media #{$breakpoint-small} {
      flex-direction: column;
      margin: 30px 0;
    }
    .card-lateral {
      width: 60%;
      height: 100%;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      transition: all linear 0.7s;
      justify-content: center;
      align-items: center;
      display: flex;
      &.product-img-congelados {
        background-image: url("../../../../assets/images/general/png/congelados.png");
      }
      &.product-img-arepas {
        background-image: url("../../../../assets/images/general/jpg/arepaT.jpg");
        @media #{$breakpoint-small} {
          background-image: url("../../../../assets/images/general/jpg/arepa.jpg");
        }
      }
      &.product-img-bebidas {
        background-image: url("../../../../assets/images/general/jpg/jugos.jpg");
      }
      &.product-img-hamburguesas {
        background-image: url("../../../../assets/images/general/jpg/hamburguer.jpg");
      }
      &.product-img-especiales {
        background-image: url("../../../../assets/images/general/jpg/costillas.jpg");
      }
      @media #{$breakpoint-small} {
        width: 100%;
        height: 25%;
      }
    }
    .card-central {
      width: 100%;
      height: 100%;
      .card-product-central {
        width: 100%;
        height: 50%;
        background-color: transparent;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        transition: all linear 0.7s;
        justify-content: center;
        align-items: center;
        display: flex;
        &.product-img-congelados {
          background-image: url("../../../../assets/images/general/png/congelados.png");
        }
        &.product-img-arepas {
          background-image: url("../../../../assets/images/general/jpg/arepaT.jpg");
          @media #{$breakpoint-small} {
            background-image: url("../../../../assets/images/general/jpg/arepa.jpg");
          }
        }
        &.product-img-bebidas {
          background-image: url("../../../../assets/images/general/jpg/jugos.jpg");
        }
        &.product-img-hamburguesas {
          background-image: url("../../../../assets/images/general/jpg/hamburguer.jpg");
        }
        &.product-img-especiales {
          background-image: url("../../../../assets/images/general/jpg/costillas.jpg");
        }
        &:hover {
          p {
            opacity: 1;
          }
        }
      }
      @media #{$breakpoint-small} {
        width: 100%;
        height: 50%;
      }
    }
  }
}

.box {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  .product-first {
    height: 100%;
    transition: all linear 0.3s;
    opacity: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .img-icon {
      transition: all linear 0.3s;
      opacity: 0;
      width: 130px;
      height: 130px;
      @media #{$breakpoint-small} {
        width: 95px;
        height: 95px;
      }
    }
    .text-description-product {
      position: absolute;
      margin: to-rem(20) 0;
      padding: to-rem(15) to-rem(60) to-rem(25) to-rem(60);
      transition: all linear 0.3s;
      background: radial-gradient(
        rgba(108, 73, 17, 0.5),
        transparent,
        transparent
      );
      text-align: center;
      text-decoration: underline;
      @extend %montserrat-500-bold;
      font-size: to-rem(25);
    }
  }
  &:hover .product-first {
    opacity: 1;
    background-color: rgba(245, 164, 0, 0.1);
    .img-icon {
      opacity: 1;
      transform: translate(0, -25px);
      -webkit-transform: translate(0, -25px);
      -o-transform: translate(0, -25px);
      -moz-transform: translate(0, -25px);
      @media #{$breakpoint-small} {
        transform: translate(0, -18px);
        -webkit-transform: translate(0, -18px);
        -o-transform: translate(0, -18px);
        -moz-transform: translate(0, -18px);
      }
    }
    .text-description-product {
      transform: translate(0, 80px);
      -webkit-transform: translate(0, 80px);
      -o-transform: translate(0, 80px);
      -moz-transform: translate(0, 80px);
      @media #{$breakpoint-small} {
        transform: translate(0, 50px);
        -webkit-transform: translate(0, 50px);
        -o-transform: translate(0, 50px);
        -moz-transform: translate(0, 50px);
      }
    }
  }
}
