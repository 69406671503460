@import "../../../../assets/scss/styles";

.text-promotions {
  width: 90%;
  @media #{$breakpoint-small} {
    width: 100%;
  }
  h1 {
    @extend %montserrat-600-bold;
    font-size: to-rem(70);
    line-height: 90%;
    @media #{$breakpoint-small} {
      font-size: to-rem(35);
    }
  }
  p {
    font-size: to-rem(30);
    font-style: italic;
    @media #{$breakpoint-small} {
      font-size: to-rem(20);
    }
  }
}
