@import "../../assets/scss/styles";

.card {
  width: 300px;
  min-width: 300px;
  height: 180px;
  background-color: white;
  margin: 40px 10px 0 10px;
  border-radius: 20px;
  display: flex;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.2);
  @media #{$breakpoint-small} {
    margin: 0 20px 0 0;
  }
  .product {
    width: 45%;
    img {
      height: 100%;
      border-radius: 20px 0 0 20px;
    }
  }
  .description {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .card-promotion {
      background-color: #5eca84;
      padding: 4px 20px;
      border-radius: 0 0 15px 15px;
      margin-bottom: 10px;
      color: white;
      font-family: $font-family-medium;
    }
    h5 {
      font-family: $font-family-medium;
      text-align: center;
      color: #845127;
    }
  }
}
