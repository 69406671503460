.badge {
  padding: 2.5px 7px;
  border-radius: 50px;
  position: absolute;
  font-weight: bold;
  font-size: 10px;
  background-color: grey;
  color: white;
  animation-name: badge;
  animation-duration: 0.1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

@keyframes badge {
  0% {
    background-color: black;
    top: -2px;
    right: -4px;
  }
  100% {
    background-color: white;
    top: 15px;
    right: -4px;
  }
}
