@import "../../../assets/scss/styles";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 15px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
  font-size: to-rem(13);
  @extend %montserrat-600-bold;
  color: #a9774a;

  &.principal {
    background-color: #e9d2ab;
  }

  &.disabled {
    background-color: #e8e8e8;
    color: #848484;
  }

  span {
    margin: 8px 25px;
  }

  &:active {
    transform: scale3d(0.9, 0.9, 1);
    -webkit-transform: scale3d(0.9, 0.9, 1);
  }
}