@import "../../assets/scss/styles";

.heightImgCarousel {
  width: 100%;
  @media #{$breakpoint-small} {
    height: to-rem(430);
    object-fit: cover;
  }
}

.content-img {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 30.5%;
  text-align: left;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: to-rem(120) to-rem(180) to-rem(80) to-rem(180);
  &.right {
    justify-content: flex-end;
  }
  @media #{$breakpoint-small} {
    font-size: 100% !important;
    padding: to-rem(80) to-rem(55) to-rem(50) to-rem(55);
  }
  .container-text {
    width: 100%;
    height: 100%;
    &.center,
    &.right {
      width: 50%;
      height: 100%;
    }
  }
  .container-text,
  .container-text.center,
  .container-text.right {
    &.text-center {
      display: flex;
      align-items: center;
    }
    &.text-bottom {
      display: flex;
      align-items: flex-end;
    }
  }
}
