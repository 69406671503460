@import "../../../assets/scss/styles";

.button {
  display: flex;
  justify-content: space-between;
  min-width: 100px;
  height: 32px;
  font-family: $font-family-medium;
  background-color: #e9d2ab;
  border-radius: 20px;
  border: 0;
  align-items: center;
  padding: 2px 2px 2px 0;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  &:active {
    transform: scale3d(0.9, 0.9, 1);
    -webkit-transform: scale3d(0.9, 0.9, 1);
  }
  span {
    width: 100%;
    text-align: center;
    padding: 0 15px;
  }
}
