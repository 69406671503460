@import "~assets/scss/styles";

.content-modal-selected-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .header-product {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    .box-img {
      display: flex;
      align-items: center;
    }
    .title {
      justify-content: space-between;
      @extend %montserrat-700-bold;
      font-size: 20px;
      color: #845127;
      p {
        align-items: center;
        margin-bottom: 0.2rem;
      }
      & p:last-child {
        font-size: 22px;
        margin-bottom: 1rem;
        color: black;
      }
    }
  }
  .circule-img {
    width: 200px;
    height: 200px;
    background-color: grey;
    border-radius: 100px;
    margin: 10px;
  }
  .box-ingredients {
    width: 100%;
    background-color: white;
    padding: 20px 0px;
    .title {
      display: flex;
      justify-content: space-between;
      @extend %montserrat-700-bold;
      font-size: 20px;
      p {
        display: flex;
        align-items: center;
      }
      & p:last-child {
        font-size: 25px;
      }
    }
    .section-ingredients {
      background-color: rgb(233, 210, 171);
      .box-padding {
        p {
          margin: 0;
          @extend %montserrat-700-bold;
          font-size: 16px;
          span {
            @extend %montserrat-100;
            font-size: 14px;
          }
        }
      }
    }
    .section-items {
      .box-padding {
        display: flex;
        justify-content: space-between;
        & div:first-child {
          & p {
            margin: 5px 0px;
          }
          & .price {
            color: red;
          }
        }
        & div:last-child {
          display: flex;
          align-items: center;
          margin: 0px 10px;
          input {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}
.box-padding {
  padding: 10px 20px;
}
