@import "../../assets/scss/styles";

#pageConstruction {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  background-attachment: fixed;
  background-color: #ffc826;
  background-image: url("../../assets/images/general/jpg/webConstruction.jpg");
  @media #{$breakpoint-small} {
    background-image: url("../../assets/images/general/jpg/WebConstractionMovil.jpg");
  }
}
