@import "../../../../assets/scss/styles";

.container {
  width: 100%;
  height: 500px;
  .content {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../../../assets/images/general/jpg/entrada-restaurante.jpg");
    background-attachment: fixed;
    opacity: 0.78;
    display: flex;
    align-items: center;
    justify-content: center;
    @media #{$breakpoint-small} {
      flex-direction: column;
    }

    $size-box-web: to-rem(260);
    $size-box-mob: to-rem(140);
    .box-icon {
      width: $size-box-web;
      height: $size-box-web;
      color: white;
      &.margin {
        margin: 0 to-rem(15);
        @media #{$breakpoint-small} {
          margin: 0 to-rem(5);
        }
      }
      &.principal {
        $size-aditional: 2.8;
        width: $size-box-web + $size-aditional;
        height: $size-box-web + $size-aditional;
        @media #{$breakpoint-small} {
          width: $size-box-mob;
          height: $size-box-mob;
        }
      }
      .img-icon {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url("../../../../assets/images/general/png/boton_erepa.png");
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0 to-rem(35);
        flex-direction: column;
        p {
          @extend %montserrat-500-bold;
          font-size: to-rem(21);
          margin: to-rem(3) 0;
          text-decoration: underline;
          cursor: pointer;
          @media #{$breakpoint-small} {
            font-size: to-rem(13);
          }
        }
      }
      @media #{$breakpoint-small} {
        width: $size-box-mob;
        height: $size-box-mob;
      }
    }
    .img-content {
      display: flex;
      align-items: center;
    }
  }
}
