@import "~assets/scss/styles";

.shopping-card {
  width: 100%;
  height: 120px;
  margin: 10px 0px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  .img-product {
    width: 25%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
  .content {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    .description {
      h6 {
        display: flex;
        align-items: center;
        font-family: $font-family-medium;
        color: #845127;
        margin: 0;
        span {
          padding: 2px 6px;
          background-color: grey;
          border-radius: 20px;
          font-size: 12px;
          color: white;
        }
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 2px 0px;
        font-size: 14px;
        &.price {
          font-family: $font-family-medium;
          margin: 0;
        }
      }
    }
    .icon-remove-product {
      margin: 0px 20px;
      &:active {
        transform: scale3d(0.9, 0.9, 1);
        -webkit-transform: scale3d(0.9, 0.9, 1);
      }
    }
  }
}
.box-padding {
  padding: 10px 20px;
}
.container-message {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  padding: 0px 20px;
}
