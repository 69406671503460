@import "../../assets/scss/styles";

.modal {
  width: 420px;
  background-color: white;
  position: fixed;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.4);
  right: 0px;
  transition: all 0.3s;
  @media #{$breakpoint-small} {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2000;
    bottom: -100vh;
  }

  &.show-in {
    bottom: 0px;
  }

  &.show-out {
    bottom: -100vh;
  }

  & .modal-header {
    width: 100%;
    height: 3%;
    background-color: white;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 15px;

    img {
      &:active {
        transform: scale3d(0.9, 0.9, 1);
        -webkit-transform: scale3d(0.9, 0.9, 1);
      }
    }
  }

  & .modal-container {
    width: 100%;
    height: 97%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    & .modal-body {
      width: 100%;
      flex-grow: 1;
      background-color: white;
      overflow-y: scroll;
    }
    & .modal-footer {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}
