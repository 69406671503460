.input-text {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid grey;
  padding: 0px 10px;
  margin: 8px 0px;
  outline: none;
  background-color: white;
}
.error-input {
  border: 1px solid red;
}
.error-message {
  margin-bottom: 0.5rem;
}
