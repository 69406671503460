@import "../../assets/scss/styles";

.contactformpage .fondo {
  background-image: url("../../assets/img/Erepa1-1920x950.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
}

#contactformpage h6 {
  line-height: 1.7;
}

#contactformpage .form-check {
  margin-bottom: 0 !important;
}

#contactformpage i {
  color: #9e9e9e !important;
}

.nobackground {
  background-color: rgba(207, 150, 43, 0.2) !important;
}

icons {
  flex-direction: row !important;
}

item {
  display: flex;
  align-items: center;
}

.imageErepa {
  margin-top: -5px;
  margin-bottom: -5px;
  width: 90px;
}

@media (max-width: 768px) {
  .navbar-collapse {
    background-color: #9e9e9e !important;
  }
}

@media (max-width: 728px) {
  .imageErepa {
    margin-top: -5px;
    width: 50px;
  }
}

.contentLinkProducts {
  display: flex;
  justify-content: center;
  width: 100%;
}

@keyframes tituloDesktop {
  0% { font-size: to-rem(20); }
  50% { font-size: to-rem(22); }
  100% { font-size: to-rem(20); }
}

@keyframes tituloMobile {
  0% { font-size: to-rem(13); }
  50% { font-size: to-rem(15); }
  100% { font-size: to-rem(13); }
}

.animationTitle {
  @extend %montserrat-200-bold;
  cursor: pointer;
  text-decoration: underline;
  // animation: tituloDesktop 2s infinite;
  // text-shadow: 2px 2px 2px black;
  // font-size: to-rem(10);
  @media #{$breakpoint-small} {
    font-size: to-rem(15);
    // animation: tituloMobile 2s infinite;
  }
}
