@import "../../../../assets/scss/styles.scss";

.content {
  width: 100%;
  display: flex;
  padding: 60px 80px;
  justify-content: center;
  background-color: rgba(white, 0.75);
  @media #{$breakpoint-small} {
    flex-direction: column;
    padding: 60px 15px;
  }
  .card-register {
    width: 50%;
    height: 100%;
    padding-left: 100px;
    @media #{$breakpoint-small} {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }
    .css-input {
      width: 300px;
      padding: 8px 20px;
      font-size: 15px;
      border-width: 1px;
      border-color: #cccccc;
      background-color: #ffffff;
      color: #000000;
      border-style: solid;
      border-radius: 20px;
    }
    .css-input:focus {
      outline: none;
    }
    .box-red-social {
      margin-top: to-rem(10);
    }
    p {
      margin: to-rem(25) 0;
    }
  }
  .card-contact {
    width: 50%;
    height: 100%;
    text-align: left;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    @media #{$breakpoint-small} {
      width: 100%;
      padding-left: 0;
      padding-top: 40px;
      h4 {
        text-align: center;
      }
    }
    .card-container {
      margin: to-rem(20) 0;
      span img {
        margin: 0 to-rem(8);
      }
      @media #{$breakpoint-small} {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
